import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/multi-arch-docker-images",
  "date": "8 March 2022",
  "title": "Multi-Arch Docker Images",
  "wip": true,
  "draft": true,
  "summary": "We are now publishing multi-architecture images",
  "author": "Kim Pepper",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "arm"
  }, {
    "name": "apple silicon"
  }, {
    "name": "multi-arch"
  }, {
    "name": "xdebug"
  }, {
    "name": "docker"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We are now shipping v2 of our base Docker images which provide multi-architecture support for both amd64 and arm64.`}</p>
    <h3>{`Apple Silicon Support`}</h3>
    <p>{`Developers who are using Apple Silicon for local development can now take advantage of
the performance of arm64 images without the need for Rosetta 2 translation layer to run them.`}</p>
    <h3>{`Nginx for Drupal`}</h3>
    <p>{`The v2 images made some other improvements for local Drupal development. Nginx now has a specific Drupal image
`}<a parentName="p" {...{
        "href": "https://hub.docker.com/repository/docker/skpr/nginx-drupal"
      }}>{`skpr/nginx-drupal`}</a>{` for proxying to Drupal running on
PHP-FPM. This means we'll be able to support different versions of Nginx config for different applications (e.g.
Symfony, Laravel, Node.js)`}</p>
    <h3>{`XDebug 3 & PHP 8.1`}</h3>
    <p>{`We now ship XDebug 3 for our development images (e.g. `}<inlineCode parentName="p">{`skpr/php-fpm:8.0-dev-v2-latest`}</inlineCode>{`). Xdebug 3 has significant
performance improvements, so we no longer need two separate dev and xdebug images. This has simplified our standard
docker-compose config.`}</p>
    <p>{`Additionally, we are now shipping `}<strong parentName="p">{`PHP 8.1`}</strong>{` in our v2 images.`}</p>
    <h3>{`New Source Repos`}</h3>
    <p>{`In order to simplify our build processes, the v2 images have been split out into their own Github repos:
`}<a parentName="p" {...{
        "href": "https://github.com/skpr/image-nginx"
      }}>{`image-nginx`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://github.com/skpr/image-php"
      }}>{`image-php`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://github.com/skpr/image-opensearch"
      }}>{`image-opensearch`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://github.com/skpr/image-node"
      }}>{`image-node`}</a>{`.`}</p>
    <p>{`This new structure should allow us to continue to support new image types and variations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      